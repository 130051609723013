import React from "react";
import clsx from "clsx";
import useFooterData from "@staticQueries/FooterQuery";
import { Container, Text, Image, Icon, Button, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const CareersList = ({
  image,
  copy,
  bgColor,
  careers,
  button,
  className: _className,
}) => {
  const colors = {
    midnight: "carnation",
    carnation: "midnight",
    mint: "midnight",
  };

  const { phone, email } = useFooterData();

  return (
    <section
      className={clsx(
        "relative py-16 md:py-32",
        {
          "bg-carnation": bgColor === "carnation",
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <Container className="relative z-10">
        <div
          className={clsx(
            "columns-1 gap-16 space-y-32 [column-rule-width:2px] [column-rule:solid] md:columns-2 md:gap-32 md:space-y-12",
            {
              "[column-rule-color:#FFFFFF]": bgColor === "carnation",
              "[column-rule-color:#9BFCD9]": bgColor === "midnight",
            }
          )}
        >
          {/* contact information */}
          <div className="break-inside-avoid space-y-12">
            {image && (
              <div className="relative aspect-square w-1/2">
                <Image image={image} fill className="rounded-full" />
              </div>
            )}
            <div className="space-y-4">
              <Text
                variant="h6"
                className={clsx({
                  "text-white": bgColor === "midnight",
                })}
              >
                Contact Us
              </Text>
              {(phone?.url || email?.url) && (
                <div className="flex flex-col space-y-2 text-sm">
                  {phone?.url && (
                    <AppLink
                      to={phone.url}
                      className={clsx("duration-300", {
                        "text-mint hover:text-white": bgColor === "midnight",
                        "hover:text-carnation": bgColor !== "midnight",
                      })}
                    >
                      {phone.text}
                    </AppLink>
                  )}
                  {email?.url && (
                    <AppLink
                      to={email.url}
                      className={clsx("duration-300", {
                        "text-mint hover:text-white": bgColor === "midnight",
                        "hover:text-carnation": bgColor !== "midnight",
                      })}
                    >
                      {email.text}
                    </AppLink>
                  )}
                </div>
              )}
            </div>
            <div>
              <Text
                richText
                className={clsx("prose-p:text-sm", {
                  "prose-headings:!text-white prose-p:text-mint":
                    bgColor === "midnight",
                })}
              >
                {copy}
              </Text>
            </div>
            <div
              className={clsx("space-y-4", {
                "text-white": bgColor === "midnight",
              })}
            >
              <Text variant="h6">
                Cause Strategy Partners is a certified B Corporation.
              </Text>
              <Icon name="bCorp" className="h-auto w-12" />
            </div>
          </div>

          {/* careers */}
          {careers.length > 1 ? (
            <div className="break-inside-avoid space-y-12">
              <Text variant="h5sans" className="text-carnation">
                Current Opportunities:
              </Text>
              {careers?.map(career => {
                const { uid, title, descriptor, uri } = career;
                return (
                  <AppLink key={uid} to={uri} className="group block">
                    <div className="flex flex-col space-y-4">
                      <Text
                        variant="h6"
                        className={clsx({
                          "text-mint duration-300 group-hover:text-white":
                            bgColor === "midnight",
                        })}
                      >
                        {title}
                      </Text>
                      <Text
                        variant="sm"
                        className={clsx({
                          "text-white": bgColor === "midnight",
                        })}
                      >
                        {descriptor}
                      </Text>
                      <ButtonAlt to={uri}>Apply</ButtonAlt>
                    </div>
                  </AppLink>
                );
              })}
            </div>
          ) : (
            <div className="flex h-full break-inside-avoid flex-col items-center">
              <Text
                variant="xl"
                className={clsx({
                  "text-white": bgColor === "midnight",
                })}
              >
                There are no available positions at this time. Please check back
                later.
              </Text>
            </div>
          )}
        </div>
        {button?.url && (
          <div className="mt-16 flex items-center justify-center">
            <Button to={button.url} color={colors[bgColor]}>
              {button.text || "Learn More"}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

CareersList.defaultProps = {};

export default CareersList;
